

    @import url("/style/css/common.css");
    .photo-warpper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .photo-warpper .photo{
        width:80px;
        height:80px;
        border-radius: 40px;
        background: #fff;
        display: inline-block;
        vertical-align: middle;
        object-fit: cover;
        border: 1px solid #ddd;
        border-radius: 50%;
        margin-bottom: 10px;
     }
     .photo-warpper .photo-selecter{
        top: 30px;
        margin-left: 10px;
        width: 22px;
     }
   .form-input{
        outline-style: none ;
        border: 1px solid #eee;
        border-radius: 3px;
        padding: 10px 0px;
        width: 100%;
        font-size: 14px;
        text-indent: 10px;
        // font-family: "Microsoft soft";
   }
   .form-input:focus{
        border-color: #ff3333;
        outline: 0;
    }
   .form-group{
        width: 100%;
        height: auto;
        background: #eee;
        border-bottom: 1px solid #eee;
    }
    .form-label{
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color:#666;
        padding: 10px 4%;
        vertical-align: middle;
        box-sizing: border-box;
    }
    .form-control{
        font-size:14px;
        vertical-align: middle;
        color:#333;
        padding: 10px 4%;
        // border:1px solid #ccc;
        background: #fff;
        box-sizing: border-box;
        box-shadow: none;
        min-height: 38px;
    }
.toolbar{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.toolbar .price{
   width: 131px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.toolbar .price .money{
    color:#ff3333;
    font-weight: 600;
}
.magic-radio:checked + label:after{
    display: block;
}
.magic-radio + label:after {
    top: 12px;
    left: 6px;
    box-sizing: border-box;
    width: 6px;
    height: 8px;
    transform: rotate(45deg);
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
    border-top: 0;
    border-left: 0;
}
.magic-radio + label:after{
    position: absolute;
    display: none;
    content: '';
}
